const config = {
  //Production baseURLs:
  // AUTH_URL: "https://backend.admin-smallofficer.com/auth",
  // API_URL: "https://backend.admin-smallofficer.com/api/v1",

  //Staging baseURLs:
  API_URL: "https://stag-backend.admin-smallofficer.com/api/v1",
  AUTH_URL: "https://stag-backend.admin-smallofficer.com/auth",
  LOGIN_URL: "/admin/login",
  GET_PROFILE: "/admin/profile",
  GET_LOCATION: "/location",
  GET_RESOURCES: "/resourcetype",
  GET_INVOICE: "/invoice",
  GET_BOOKING: "/booking",
  GET_ANNOUCEMENTS: "/announcement",
  UPDATE_ANNOUCEMENTS: "/announcement",
  ADD_ANNOUCEMENTS: "/announcement",
  INFO: "/info",
  RESOURCES: "/resource",
  RESOURCES_TYPES: "/resourcetype",
  GET_USER: "/user",
  DASHBOARD: "/dashboard",
  LOCKS: "https://api.kisi.io/locks",
  UNIVERSAL_URL: "https://www.universal-tutorial.com/api",
  GET_UNIVERSAL_TOKEN: "/getaccesstoken",
  GET_COUNTRY: "/countries",
  GET_STATE: "/states/",
  GET_CITY: "/cities/",
  GET_TEAM: "/team",
  PLANS: "/plan",
  NOTIFICATION: "/user/sendpushnotification",
  REPORTS: "/appreport",
  VERIFY_USER: "/user/verify",
  USER_SIGNUP: "/user/signup",
  WEEK_DAY_TIME: "/weekdaytime",
};

export default config;
